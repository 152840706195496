import i18n from '@/i18n/i18n';

const validatesPresence = (
  vueReactive,
  fieldName,
  errorFieldName,
  selectInput = false
) => {
  if (vueReactive[fieldName]) {
    vueReactive[errorFieldName] = '';
    return true;
  }

  vueReactive[errorFieldName] = selectInput
    ? i18n.t('validations.shared.mustBeSelected')
    : i18n.t('validations.shared.mustBeFilled');
  return false;
};

const validatesGreaterThan = (
  vueReactive,
  fieldName,
  errorFieldName,
  operand
) => {
  if (
    vueReactive[fieldName] &&
    parseFloat(vueReactive[fieldName]) > parseFloat(operand)
  ) {
    vueReactive[errorFieldName] = '';
    return true;
  }

  vueReactive[errorFieldName] =
    i18n.t('validations.shared.greaterThan') + operand;
  return false;
};

const validatesGreaterThanOrEqualTo = (
  vueReactive,
  fieldName,
  errorFieldName,
  operand
) => {
  const value = parseFloat(vueReactive[fieldName]);
  operand = parseFloat(operand);

  if (!isNaN(value) && (value > operand || value === operand)) {
    vueReactive[errorFieldName] = '';
    return true;
  }

  vueReactive[errorFieldName] =
    i18n.t('validations.shared.greaterThanOrEqualTo') + operand;
  return false;
};

const clearErrors = (model, suffix = 'error') => {
  for (const attr in model) {
    // Clearing all error messages
    if (attr.toLowerCase().endsWith(suffix)) model[attr] = '';
  }
};

const isModelValid = (rawModel, suffix = 'error') => {
  for (const attr in rawModel) {
    // Checking all error messages, returning false if any error is found.
    if (attr.toLowerCase().endsWith(suffix) && rawModel[attr]) return false;
  }

  return true;
};

export {
  validatesPresence,
  validatesGreaterThan,
  validatesGreaterThanOrEqualTo,
  clearErrors,
  isModelValid,
};
