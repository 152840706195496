<template>
  <div v-if="loaded" class="container-fluid" style="min-height: 800px">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="my-3">Inspeção de qualidade</h1>

        <router-link v-if="conditionalApprovalMode" :to="{
          name: 'QualityInspectionConditionalApprovalList',
        }" class="mb-3 btn btn-outline-dark">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </router-link>
        <router-link v-else :to="{
          name: 'QualityInspectionList',
          params: { productId: product._id },
        }" class="mb-3 btn btn-outline-dark">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </router-link>

        <div class="mb-2 fw-bold">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.qualityInspection.passed') + ': '
            }}</span>
          <span :class="hasPassedInspectionClasses" class="d-inline-block">{{
            hasPassedInspection
            }}</span>
        </div>

        <div class="mb-2 fw-bold">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.product.qaIdentifier') + ': '
            }}</span>
          <span class="d-inline-block">{{ product.qaIdentifier }}</span>
        </div>

        <div class="mb-2 fw-bold">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.product.mineratecIdentifier') + ': '
            }}</span>
          <span class="d-inline-block">{{ product.mineratecIdentifier }}</span>
        </div>

        <div class="mb-2">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.qualityInspection.inspectorFullName') + ': '
            }}</span>
          <span class="d-inline-block">{{ qi.inspectorFullName }}</span>
        </div>

        <div class="mb-2">
          <span style="width: 200px" class="d-inline-block">{{
            i18n.t('models.shared.observations') + ': '
            }}</span>
          <span class="d-inline-block">{{
            appViewHelper.format(qi.observations)
            }}</span>
        </div>

        <div v-if="qi.conditionalApprovalStatus === 'approved' || qi.conditionalApprovalStatus === 'rejected'"
          class="my-5">
          <div class="mb-2">
            <span style="width: 200px" class="d-inline-block">{{
              i18n.t('models.qualityInspection.conditionalApprovalReviewedBy') + ': '
              }}</span>
            <span class="d-inline-block">{{ qi.conditionalApprovalReviewedByFullName }}</span>
          </div>

          <div class="mb-2">
            <span style="width: 200px" class="d-inline-block">{{
              i18n.t('models.qualityInspection.conditionalApprovalReviewObs') + ': '
              }}</span>
            <span class="d-inline-block">{{ appViewHelper.format(qi.conditionalApprovalReviewObs) }}</span>
          </div>

          <div class="mb-2">
            <span style="width: 200px" class="d-inline-block">{{
              i18n.t('models.qualityInspection.conditionalApprovalReviewedAt') + ': '
              }}</span>
            <span class="d-inline-block">{{ appViewHelper.formatDateTime(qi.conditionalApprovalReviewedAt) }}</span>
          </div>
        </div>

        <div class="mb-2" v-for="(
            labelAndValue, index
          ) in appViewHelper.printRecordDefaultFields(qi)" :key="index">
          <span style="width: 200px" class="d-inline-block">{{
            labelAndValue[0] + ': '
            }}</span>
          <span class="d-inline-block">{{ labelAndValue[1] }}</span>
        </div>

        <div class="mt-5">
          <h3 class="text-success">Itens aprovados</h3>
          <div v-if="anyPassedItem">
            <div v-for="(
                sectionItems, section, index
              ) in passedChecklistItemsGroupedBySection" :key="`passed_item_sections_${index}`">
              <label class="form-label mt-4 text-uppercase d-block">{{
                section
                }}</label>
              <ul class="d-block list-unstyled">
                <li class="mb-3" v-for="(item, index) in sectionItems" :key="`passed_item_${index}`">
                  <i class="text-success bi bi-check2"></i>
                  {{ item.text }}<br>
                  <span v-if="item.measurement">
                    (Medido: {{ checklistItemMeasurementTakenText(qi.checklistMeasurements[item.value].measurementTaken,
                      item) }}
                    / Esperado: {{ checklistItemExpectedMeasurementText(item) }})
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <p v-else>Nenhum item de inspeção foi aprovado.</p>
        </div>

        <div class="mt-5">
          <h3 class="text-danger">Itens reprovados</h3>
          <div v-if="anyFailedItem">
            <div v-for="(
                sectionItems, section, index
              ) in failedChecklistItemsGroupedBySection" :key="`failed_item_sections_${index}`">
              <label class="form-label mt-4 text-uppercase d-block">{{
                section
                }}</label>
              <ul class="d-block list-unstyled">
                <li class="mb-3" v-for="(item, index) in sectionItems" :key="`failed_item_${index}`">
                  <i class="text-danger bi bi-x-octagon"></i>
                  {{ item.text }}<br>
                  <span v-if="item.measurement">
                    (Medido: {{ checklistItemMeasurementTakenText(qi.checklistMeasurements[item.value].measurementTaken,
                      item) }}
                    / Esperado: {{ checklistItemExpectedMeasurementText(item) }})
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <p v-else>Nenhum item de inspeção foi reprovado.</p>
        </div>

        <div class="my-5">
          <h3>Fotos da inspeção</h3>
          <CouchDbImageGallery v-if="attachmentCollectionId" title="" :attachmentCollectionId="attachmentCollectionId"
            :database="remoteDb" />
          <p v-else>
            Essa inspeção não possui fotos, elas ainda não foram sincronizadas
            ou a conexão com o servidor falhou.
          </p>
        </div>

        <div v-if="conditionalApprovalMode" class="mb-5">
          <h3>Análise da solicitação de aprovação condicional</h3>
          <p>
            Após analisar as <b>observações, itens aprovados e reprovados, além das fotos</b>, qual o seu julgamento
            sobre aprovar condicionalmente essa inspeção?
          </p>
          <form novalidate>
            <label for="qi-ca-review-obs-text-area" class="mt-3 form-label">Observaçōes do revisor</label>
            <textarea id="qi-ca-review-obs-text-area" class="form-control" rows="5"
              v-model="conditionalApprovalReviewObs"></textarea>

            <label for="qi-ca-reviewer-select" class="mt-4 form-label">Revisor</label>
            <select id="qi-ca-reviewer-select" class="form-select" v-model="conditionalApprovalReviewedBy">
              <option v-for="reviewer in qiCaReviewers.textAndValues" :key="reviewer.value" :value="reviewer.value">
                {{ reviewer.text }}
              </option>
            </select>
            <div class="text-danger">{{ conditionalApprovalReviewedByError }}</div>

            <label for="qi-ca-reviewer-pin-input" class="mt-4 form-label">PIN do revisor</label>
            <input type="password" id="qi-ca-reviewer-pin-input" class="form-control"
              v-model.number="conditionalApprovalReviewerPin">
            <div class="text-danger">{{ conditionalApprovalReviewerPinError }}</div>

            <div class="mt-4">
              <button type="button" class="btn btn-success me-3" @click="completeConditionalApproval(true)">
                <i class="bi bi-hand-thumbs-up d-inline-block me-1"></i>
                Aprovar
              </button>
              <button type="button" class="btn btn-danger" @click="completeConditionalApproval(false)">
                <i class=" bi bi-hand-thumbs-down d-inline-block me-1"></i>
                Reprovar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import ChecklistHelper from '../helpers/checklist_helper';
import { checklistItemExpectedMeasurementText, checklistItemMeasurementTakenText } from '../helpers/checklist_item_helper';
import appViewHelper from '../view_helpers/app_view_helper';
import prodOrderDao from '../dao/production_order_dao';
import qualityInspectionDao from '../dao/quality_inspection_dao';
import attachmentDao from '../dao/attachment_dao';
import QaDao from '../dao/qa_dao';
import { RemoteDb, buildRemoteDbAddr } from '../db/db';
import CouchDbImageGallery from '../components/CouchDbImageGallery';
import store from '../store/store';
import { validatesPresence, clearErrors } from '../validators/ui_validators';
import { useToast } from 'vue-toastification';

export default {
  name: 'ShowQualityInspection',
  components: {
    CouchDbImageGallery,
  },
  props: {
    qualityInspectionId: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: 'default'
    }
  },
  setup() {
    return { i18n, appViewHelper, checklistItemExpectedMeasurementText, checklistItemMeasurementTakenText, toast: useToast() };
  },
  created() {
    store.commit('activateFullscreenOverlay');

    qualityInspectionDao.getObjById(this.qualityInspectionId, (qi) => {
      this.qi = qi;
      this.checklist = this.qi.checklist;

      qualityInspectionDao.getObjById(this.qi.productId, (product) => {
        this.product = product;

        const qaDao = new QaDao();
        qaDao.getObjById('pin::qualityInspectionConditionalApproval', (qiCaReviewers) => {
          this.qiCaReviewers = qiCaReviewers;

          this.qiCaReviewers.textAndValues.forEach(r => this.qiCaReviewerFullNames[r.value] = r.text);

          this.loaded = true;
          store.commit('deactivateFullscreenOverlay');
        });
      });
    });

    buildRemoteDbAddr('workspaceAttachments', (remoteDbAddr) => {
      this.remoteDb = new RemoteDb(remoteDbAddr);
      attachmentDao.getAttachmentsForObj(
        this.remoteDb,
        [this.qualityInspectionId],
        (attachmentCollections) => {
          if (attachmentCollections.length > 0)
            this.attachmentCollectionId = attachmentCollections[0]._id;
        }
      );
    });
  },
  data() {
    return {
      loaded: false,
      qi: null,
      product: null,
      checklist: null,
      attachmentCollectionId: null,
      qiCaReviewers: null,
      qiCaReviewerFullNames: {},
      conditionalApprovalReviewObs: '',
      conditionalApprovalReviewedBy: null,
      conditionalApprovalReviewedByError: '',
      conditionalApprovalReviewerPin: null,
      conditionalApprovalReviewerPinError: '',
      remoteDb: null,
    };
  },
  computed: {
    conditionalApprovalMode() {
      return this.mode === 'conditionalApproval';
    },
    hasPassedInspection() {
      if (this.qi.conditionalApprovalStatus === 'waitingAuthorization') {
        return 'Aguardando aprovação condicional';
      } else if (this.qi.passed) {
        return this.qi.conditionalApprovalStatus === 'approved' ? 'Aprovada (condicional)' : 'Aprovada';
      } else {
        return 'Reprovada';
      }
    },
    hasPassedInspectionClasses() {
      if (this.qi.conditionalApprovalStatus === 'waitingAuthorization') {
        return 'text-warning';
      } else {
        return this.qi.passed ? 'text-success' : 'text-danger';
      }
    },
    anyPassedItem() {
      return Object.keys(this.passedChecklistItemsGroupedBySection).length > 0;
    },
    anyFailedItem() {
      return Object.keys(this.failedChecklistItemsGroupedBySection).length > 0;
    },
    passedChecklistItemsGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(
        this.checklist,
        this.qi.passedChecklistItems
      );
    },
    failedChecklistItemsGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(
        this.checklist,
        this.qi.failedChecklistItems
      );
    },
  },
  methods: {
    isConditionalApprovalValid() {
      clearErrors(this);

      validatesPresence(this, 'conditionalApprovalReviewedBy', 'conditionalApprovalReviewedByError', true);
      if (!this.conditionalApprovalReviewedBy) return false;

      if (this.conditionalApprovalReviewerPin !== this.qiCaReviewers.pins[this.conditionalApprovalReviewedBy]) {
        this.conditionalApprovalReviewerPinError = i18n.t('validations.shared.incorrect');
        return false;
      }

      return true;
    },
    async completeConditionalApproval(isApproved) {
      store.commit('activateFullscreenOverlay');
      if (!this.isConditionalApprovalValid()) {
        store.commit('deactivateFullscreenOverlay');
        return;
      }

      const updatedQi = Object.assign({},
        this.qi,
        {
          passed: isApproved,
          conditionalApprovalStatus: isApproved ? 'approved' : 'rejected',
          conditionalApprovalReviewedBy: this.conditionalApprovalReviewedBy,
          conditionalApprovalReviewedByFullName: this.qiCaReviewerFullNames[this.conditionalApprovalReviewedBy],
          conditionalApprovalReviewedAt: new Date().toISOString(),
          conditionalApprovalReviewObs: this.conditionalApprovalReviewObs
        });
      await qualityInspectionDao.updateObj(updatedQi);

      const prodOrder = await prodOrderDao.getObjById(this.product.productionOrderId);
      prodOrderDao.checkIfAllProductsPassed(prodOrder, (_prodOrderId) => {
        this.toast.success(i18n.translate('actions.saved'));
        this.$router.push({
          name: 'QualityInspectionConditionalApprovalList'
        });

        store.commit('deactivateFullscreenOverlay');
      });
    }
  }
};
</script>
