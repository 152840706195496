export default class ProductHelper {
  static hasInspectionPendingConditionalApproval(qis) {
    return qis.some(
      (qi) => qi.conditionalApprovalStatus === 'waitingAuthorization'
    );
  }

  static hasPassedInspection(qis) {
    return qis.some((qi) => qi.passed);
  }

  static qaIdentifierProductSequencePart(qaIdentifier) {
    return qaIdentifier.split('/')[1];
  }
}
