<template>
  <div class="row justify-content-center">
    <div class="col col-md-6">
      <div class="card mnrt-card mb-4">
        <div class="card-header mnrt-card-header">
          <h3>{{ viewHelper.formatDateTime(qi.createdAt) }}</h3>
        </div>
        <div class="card-body">
          <template v-if="conditionalApprovalMode && product">
            <h5>
              Código QA: {{ product.qaIdentifier }}
            </h5>
            <h5>
              Produto: {{ product.mineratecIdentifier }}
            </h5>
          </template>
          <h5>
            Inspecionado por: {{ qi.inspectorFullName }}
          </h5>
          <h5 v-if="!conditionalApprovalMode" :class="hasPassedInspectionClasses">{{ hasPassedInspection }}</h5>
          <router-link :to="{
            name: 'ShowQualityInspection',
            params: { qualityInspectionId: qi._id },
            query: { mode: mode }
          }" class="card-link btn btn-outline-dark">
            <template v-if="conditionalApprovalMode">
              <i class="bi bi-hand-thumbs-up me-2"></i>
              <i class="bi bi-hand-thumbs-down"></i>
            </template>
            <i v-else class="bi bi-file-text"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import viewHelper from '../view_helpers/app_view_helper';
import qiDao from '../dao/quality_inspection_dao';

export default {
  name: 'QualityInspectionCard',
  props: {
    qi: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: 'default'
    }
  },
  setup() {
    return { i18n, viewHelper };
  },
  data() {
    return {
      product: null,
    };
  },
  async created() {
    this.product = await qiDao.getObjById(this.qi.productId);
  },
  computed: {
    hasPassedInspection() {
      if (this.qi.conditionalApprovalStatus === 'waitingAuthorization') return 'Aguardando aprovação';
      if (this.qi.passed) {
        return this.qi.conditionalApprovalStatus === 'approved' ? 'Aprovada (condicional)' : 'Aprovada';
      } else {
        return 'Reprovada';
      }
    },
    hasPassedInspectionClasses() {
      if (this.qi.conditionalApprovalStatus === 'waitingAuthorization') return 'text-warning';
      return this.qi.passed ? 'text-success' : 'text-danger';
    },
    conditionalApprovalMode() {
      return this.mode === 'conditionalApproval';
    }
  },
};
</script>
