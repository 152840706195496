<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Inspeçōes aguardando aprovação condicional</h1>
        <router-link :to="{ name: 'ProductionOrderList' }" class="d-block mt-5 mb-3 btn btn-outline-dark"
          style="max-width: 290px">
          <i class="bi bi-arrow-left"></i>
          Voltar para ordens de produção
        </router-link>
      </div>
      <template v-if="anyQiWaitingAuthorization">
        <div class="row justify-content-center">
          <div class="col col-md-6">
            <h6 class="my-3">
              <i>Exibindo da inspeção mais recente para a mais antiga</i>
            </h6>
          </div>
        </div>
        <QualityInspectionCard v-for="qi in qiWaiting" :key="qi._id" :qi="qi" mode="conditionalApproval" />
      </template>
      <template v-else>
        <div class="row justify-content-center">
          <div class="col col-md-6">
            <p class="my-3">
              Não há inspeções de qualidade aguardando aprovação condicional no momento.
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import qualityInspectionDao from '../dao/quality_inspection_dao'
import store from '../store/store'
import QualityInspectionCard from '../components/QualityInspectionCard'

let qiWaiting = ref([])
const anyQiWaitingAuthorization = computed(() => { return qiWaiting.value.length > 0 })

onMounted(async () => {
  store.commit('activateFullscreenOverlay')

  qiWaiting.value = await qualityInspectionDao.getWaitingQualityInspections()

  store.commit('deactivateFullscreenOverlay')
})
</script>