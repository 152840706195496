<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div v-if="loaded" class="col-10 col-md-6">
        <h1 class="mt-3">Inspeção de qualidade</h1>
        <h4 class="mt-3">{{ product.qaIdentifier }}</h4>
        <h5 class="mt-3">{{ product.mineratecIdentifier }}</h5>
        <form v-show="!showSummarizeAndConfirmDialog && !showConditionalApprovalDialog" class="my-4" novalidate>
          <label for="qi-inspector-select" class="form-label">Responsável pela inspeção</label>
          <select id="qi-inspector-select" class="form-select" v-model="qiInspector">
            <option v-for="inspector in inspectors" :key="inspector.value" :value="inspector.value">
              {{ inspector.text }}
            </option>
          </select>
          <div class="text-danger">{{ qiInspectorError }}</div>

          <label class="form-label mt-4 text-uppercase">Etiquetagem</label>
          <div class="form-check form-switch mnrt-form-switch">
            <input class="form-check-input cursor-pointer" id="qi-is-labeled" type="checkbox" value="true"
              v-model="qiIsLabeled" />
            <label class="form-check-label">Confirmo que etiquetei o produto</label>
          </div>
          <div class="text-danger">{{ qiIsLabeledError }}</div>

          <label class="form-label mt-5 text-uppercase d-block">Checklist</label>
          <div v-for="(sectionItems, section, index) in checklistGroupedBySection"
            :key="`vue_checklist_section_${index}`">
            <label class="form-label mt-5 text-uppercase d-block">{{
              section
              }}</label>
            <div v-for="checklistItem in sectionItems" :key="`vue_checklist_item_${checklistItem.value}`"
              class="form-check form-switch mnrt-form-switch mt-5">
              <input class="form-check-input cursor-pointer" :id="`checklist_item_${checklistItem.value}`"
                type="checkbox" :value="checklistItem.value" v-model="qiChecklist"
                :disabled="checklistItem.measurement" />
              <label class="form-check-label">
                <span class="d-inline-block me-2">{{ checklistItem.text }}</span>
                <i v-if="checklistItem.help" class="bi-question-square cursor-pointer me-2"
                  @click="showChecklistItemHelp(checklistItem)">
                </i>
                <i v-if="checklistItem.measurement" class="bi-rulers">
                </i>
              </label>
              <div v-if="checklistItem.measurement" class="mt-2">
                <span class="me-2">Medida aferida:</span>
                <input type="number" v-model="qiMeasurements[checklistItem.value].measurementTaken"
                  class="form-control d-inline-block me-2" style="max-width: 100px;" />
                <span>{{ checklistItem.measurement.unit }}</span>
                <div class="mt-2 text-danger">
                  {{ qiMeasurementErrors[checklistItem.value] }}
                </div>
                <div class="mt-2" v-if="qiMeasurements[checklistItem.value].measurementTaken != null">
                  <span class="me-1">Resultado:</span>
                  <b v-show="qiMeasurementResults[checklistItem.value]" class="text-success me-1">aprovado</b>
                  <b v-show="!qiMeasurementResults[checklistItem.value]" class="text-danger me-1">reprovado</b>
                  <span>(valor esperado: {{ checklistItemExpectedMeasurementText(checklistItem) }})</span>
                </div>
              </div>
            </div>
          </div>

          <label for="qi-obs-text-area" class="mt-4 form-label">Observaçōes adicionais</label>
          <textarea id="qi-obs-text-area" class="form-control" rows="5" v-model="qiObs"></textarea>

          <label class="form-label mt-4 text-uppercase">Fotos</label>
          <Camera ref="camera" idleText="Tirar fotos do produto" />

          <div v-show="hasErrors" class="text-danger">
            {{ i18n.t('validations.shared.formErrorsSummary') }}
          </div>
          <div class="my-4">
            <button type="button" @click="normalFlow" class="d-block mt-3 btn btn-outline-primary">{{
              normalFlowBtnTxt()
              }}</button>
            <button type="button" v-if="!hasPassedInspection" @click="conditionalApprovalFlow"
              class="d-block mt-3 btn btn-outline-warning">
              Solicitar aprovação condicional
            </button>
            <button type="button" class="d-block mt-3 btn btn-outline-dark">Voltar</button>
          </div>
        </form>
        <SummarizeAndConfirmDialog v-show="showSummarizeAndConfirmDialog" @dialogCompleted="handleDialogCompleted">
          <template v-slot:body>
            <div v-show="!hasPhotos()" class="alert alert-warning">
              É fortemente recomendável que você adicione algumas fotos para
              documentar a inspeção do produto.
            </div>
            <div v-if="hasPassedInspection">
              <p>
                Você está <b class="text-success">aprovando</b> esse produto na
                inspeção de qualidade.
              </p>
              <p>É isso mesmo que deseja fazer?</p>
            </div>
            <div v-else>
              <p>
                Você está <b class="text-danger">reprovando</b> esse produto na
                inspeção de qualidade.
              </p>
              <p>
                O produto <b class="text-danger">falhou</b> nos seguintes itens
                de inspeção:
              </p>
              <div v-for="(
                  sectionItems, section, index
                ) in failedChecklistGroupedBySection" :key="`failed_checklist_section_${index}`">
                <label class="form-label mt-4 text-uppercase d-block">{{
                  section
                  }}</label>
                <ul class="d-block my-4 list-unstyled">
                  <li class="mb-3" v-for="(failedChecklistItem, index) in sectionItems"
                    :key="`failed_checklist_item_${index}`">
                    <i class="bi bi-x-octagon"></i>
                    {{ failedChecklistItem.text }}<br>
                    <span v-if="failedChecklistItem.measurement">
                      (Medido: {{
                        checklistItemMeasurementTakenText(qiMeasurements[failedChecklistItem.value].measurementTaken,
                          failedChecklistItem) }} / Esperado: {{ checklistItemExpectedMeasurementText(failedChecklistItem)
                      }})
                    </span>
                  </li>
                </ul>
              </div>
              <p>É isso mesmo que deseja fazer?</p>
            </div>
          </template>
        </SummarizeAndConfirmDialog>
        <ConditionalApprovalDialog :observations="qiObs" v-show="showConditionalApprovalDialog"
          @caFlowCompleted="handleConditionalApprovalCompleted" />
        <ChecklistItemHelpModal :helpText="currentChecklistItemHelpText" ref="helpModal" />
      </div>
    </div>
  </div>
</template>

<script>
import Camera from '../components/Camera';
import QaDao from '../dao/qa_dao';
import productDao from '../dao/product_dao';
import productionOrderDao from '../dao/production_order_dao';
import checklistDao from '../dao/checklist_dao';
import qualityInspectionDao from '../dao/quality_inspection_dao';
import attachmentDao from '../dao/attachment_dao';
import SummarizeAndConfirmDialog from '../components/SummarizeAndConfirmDialog';
import i18n from '../i18n/i18n';
import store from '../store/store';
import { useToast } from 'vue-toastification';
import ChecklistHelper from '../helpers/checklist_helper';
import { checklistItemExpectedMeasurementText, checklistItemMeasurementTakenText } from '../helpers/checklist_item_helper';
import ChecklistItemHelpModal from '../components/ChecklistItemHelpModal';
import ConditionalApprovalDialog from '../components/ConditionalApprovalDialog.vue';

export default {
  name: 'QualityInspection',
  components: {
    Camera,
    SummarizeAndConfirmDialog,
    ConditionalApprovalDialog,
    ChecklistItemHelpModal,
  },
  props: {
    productId: {
      required: true,
      type: String,
    },
  },
  setup() {
    return { i18n, toast: useToast(), checklistItemExpectedMeasurementText, checklistItemMeasurementTakenText };
  },
  created() {
    // Generates watchers functions for specific checklist items as needed (i.e. if they require measurements)
    const genWatchMeasurementTaken = (changedItemId) => {
      return (newMeasurementTaken) => {
        const measurementExpected = this.checklist.items[changedItemId].measurement;

        this.qiMeasurementResults[changedItemId] =
          newMeasurementTaken >= (measurementExpected.idealValue - measurementExpected.toleranceLowerBound) &&
          newMeasurementTaken <= (measurementExpected.idealValue + measurementExpected.toleranceUpperBound);

        // We add or remove the itemId from qiChecklist (which contains passed checklist items) depending on the comparison
        // between the specifications and the actual measurement taken.
        if (this.qiMeasurementResults[changedItemId]) {
          this.qiChecklist.push(changedItemId);
        } else {
          this.qiChecklist = this.qiChecklist.filter((itemId) => itemId !== changedItemId);
        }
      };
    };

    productDao.getObjById(this.productId, (product) => {
      this.product = product;

      const qaDao = new QaDao();
      qaDao.getObjById('collection::inspectors', (inspectors) => {
        this.inspectors = inspectors.textAndValues;

        checklistDao.getAppropriateChecklist(
          this.product.mineratecIdentifier,
          (checklist) => {
            this.checklist = checklist;

            for (const itemId in checklist.items) {
              const item = checklist.items[itemId];
              if (item.measurement) {
                this.qiMeasurements[itemId] = { measurementTaken: null };
                this.qiMeasurementErrors[itemId] = '';
                this.qiMeasurementResults[itemId] = false;
                this.$watch(`qiMeasurements.${itemId}.measurementTaken`, genWatchMeasurementTaken(itemId));
              }
            }

            productionOrderDao.getObjById(
              this.product.productionOrderId,
              (productionOrder) => {
                this.productionOrder = productionOrder;

                this.loaded = true;
              }
            );
          }
        );
      });
    });
  },
  data() {
    return {
      loaded: false,
      inspectors: [],
      checklist: null,
      product: { mineratecIdentifier: '', qaIdentifier: '' },
      productionOrder: null,
      showChecklistItemHelpModal: false,
      showSummarizeAndConfirmDialog: false,
      showConditionalApprovalDialog: false,
      currentChecklistItemHelpText: '',
      qiInspector: null,
      qiInspectorError: '',
      qiIsLabeled: false,
      qiIsLabeledError: '',
      qiChecklist: [],
      qiMeasurements: {},
      qiMeasurementErrors: {},
      qiMeasurementResults: {},
      qiConditionalApprovalStatus: 'notApplicable',
      qiObs: '',
    };
  },
  methods: {
    hasPhotos() {
      if (!this.$refs.camera) return false;
      return this.$refs.camera.anyFiles();
    },
    normalFlowBtnTxt() {
      if (this.hasPassedInspection) {
        return 'Continuar aprovação';
      } else {
        return 'Continuar reprovação';
      }
    },
    normalFlow() {
      this.validate();
      if (this.hasErrors) return;

      this.showSummarizeAndConfirmDialog = true;
    },
    conditionalApprovalFlow() {
      this.validate();
      if (this.hasErrors) return;

      this.showConditionalApprovalDialog = true;
    },
    clearPreviousErrors() {
      this.qiInspectorError = '';
      this.qiIsLabeledError = '';
      for (const itemId in this.qiMeasurementErrors) {
        this.qiMeasurementErrors[itemId] = '';
      }
    },
    validate() {
      this.clearPreviousErrors();

      if (this.qiInspector === null || this.qiInspector === '')
        this.qiInspectorError = i18n.translate(
          'validations.shared.mustBeSelected'
        );

      if (!this.qiIsLabeled)
        this.qiIsLabeledError = i18n.translate(
          'validations.qualityInspection.productRequiresLabeling'
        );

      for (const itemId in this.qiMeasurements) {
        const measurement = this.qiMeasurements[itemId].measurementTaken;
        if ((typeof measurement) != 'number' || measurement < 0) {
          this.qiMeasurementErrors[itemId] = i18n.translate('validations.qualityInspection.measurementMustNotBeNegative');
        }
      }
    },
    showChecklistItemHelp(checklistItem) {
      this.currentChecklistItemHelpText = checklistItem.help;
      this.$refs.helpModal.open();
    },
    handleDialogCompleted(confirmed) {
      if (confirmed) {
        this.save();
      } else {
        this.showSummarizeAndConfirmDialog = false;
      }
    },
    handleConditionalApprovalCompleted(caFlow) {
      this.qiObs = caFlow.obs;

      if (caFlow.continueFlow) {
        this.qiConditionalApprovalStatus = 'waitingAuthorization';
        this.save();
      } else {
        this.showConditionalApprovalDialog = false;
      }
    },
    save() {
      store.commit('activateFullscreenOverlay');

      const qi = {
        productId: this.productId,
        checklist: this.checklist,
        inspector: this.qiInspector,
        inspectorFullName: this.qiInspectorFullName,
        passed: this.hasPassedInspection,
        passedChecklistItems: this.qiChecklist,
        failedChecklistItems: this.failedChecklistItemIdentifiers,
        checklistMeasurements: Object.keys(this.qiMeasurements).length > 0 ? this.qiMeasurements : null,
        conditionalApprovalStatus: this.qiConditionalApprovalStatus,
        observations: this.qiObs
      };
      qualityInspectionDao.saveQualityInspection(qi, (qiId) => {
        if (this.hasPhotos()) {
          const metadata = {
            ownerDocId: qiId,
          };
          this.$refs.camera.waitForOptimizedFiles((files) => {
            attachmentDao.saveAttachments(metadata, files, this.afterSave);
          });
        } else {
          this.afterSave();
        }
      });
    },
    afterSave() {
      productionOrderDao.checkIfAllProductsPassed(this.productionOrder, () => {
        store.commit('deactivateFullscreenOverlay');
        this.toast.success(i18n.translate('actions.saved'));
        this.$router.push({
          path: '/',
        });
      });
    },
  },
  computed: {
    hasErrors() {
      return (
        [this.qiInspectorError, this.qiIsLabeledError, ...Object.values(this.qiMeasurementErrors)].filter((el) => el !== '')
          .length > 0
      );
    },
    qiInspectorFullName() {
      if (!this.qiInspector) return '';
      return this.inspectors.find(
        (inspector) => inspector.value === this.qiInspector
      ).text;
    },
    checklistGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(this.checklist);
    },
    checklistItemIdentifiers() {
      return Object.keys(this.checklist.items).sort();
    },
    hasPassedInspection() {
      if (this.qiChecklist.length === 0) return false;

      return (
        [...this.qiChecklist].sort().toString() ===
        this.checklistItemIdentifiers.toString()
      );
    },
    failedChecklistItemIdentifiers() {
      return this.checklistItemIdentifiers.filter(
        (itemIdentifier) => !this.qiChecklist.includes(itemIdentifier)
      );
    },
    failedChecklistGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(
        this.checklist,
        this.failedChecklistItemIdentifiers
      );
    },
  }
};
</script>
