<template>
  <div class="my-4">
    <p>
      Você está <b>solicitando a aprovação condicional</b> dessa inspeção de qualidade.
      Nesse caso, é essencial que você documente resumidamente o porquê essa inspeção deve
      ser aceita apesar dos itens fora de especificação.
    </p>
    <p>
      <template v-if="observations.length > 0">
        Complemente abaixo, se necessário, as observações já feitas.
      </template>
      <template v-else>
        <b>Justifique, abaixo, o porquê deve ocorrer a aprovação condicional.</b>
        Em outras palavras, como aquilo fora de especificação não deve impactar o funcionamento
        ou o aceite do produto pelo cliente.
      </template>
    </p>

    <label for="qi-obs-ca-flow-text-area" class="mt-4 form-label">Observaçōes adicionais</label>
    <textarea id="qi-obs-ca-flow-text-area" class="form-control" rows="5" v-model.trim="qiCaFlow.obs"></textarea>
    <p class="mt-1 text-danger">{{ qiCaFlow.obsError }}</p>

    <div v-show="showErrorsSummary" class="text-danger">
      {{ i18n.t('validations.shared.formErrorsSummary') }}
    </div>
    <button class="d-block mt-3 btn btn-outline-warning" style="min-width: 300px" @click="continueAction">
      Solicitar aprovação condicional
    </button>
    <button class="d-block mt-3 btn btn-outline-dark" style="min-width: 195px" @click="cancelAction">
      {{ i18n.t('actions.amend') }}
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, reactive, toRaw, watch } from 'vue'
import i18n from '../i18n/i18n'
import { validatesPresence, clearErrors, isModelValid } from '../validators/ui_validators'

const props = defineProps({
  observations: String
})

const emit = defineEmits(['caFlowCompleted'])
const qiCaFlow = reactive({ obs: '', obsError: '', continueFlow: true })

let showErrorsSummary = ref(false)

watch(() => props.observations, (newValue, _oldValue) => {
  qiCaFlow.obs = `${newValue}`
})

const validate = () => {
  showErrorsSummary = false
  clearErrors(qiCaFlow)

  validatesPresence(qiCaFlow, 'obs', 'obsError')
  if (isModelValid(toRaw(qiCaFlow))) {
    return true
  } else {
    showErrorsSummary = true
    return false
  }
}

const continueAction = () => {
  if (!validate()) return false
  qiCaFlow.continueFlow = true
  emit('caFlowCompleted', qiCaFlow)
}

const cancelAction = () => {
  qiCaFlow.continueFlow = false
  emit('caFlowCompleted', qiCaFlow)
}
// Botões Aprovar ou reprovar e Solicitar aprovação condicional
// Aprovação condicional marcará o novo campo conditionalApprovalStatus como string
// Novo status permitirá continuar o fluxo e aprovar condicionalmente (permitindo a edição das observações) ou reprovar
// A aprovação condicional talvez deverá acontecer num status diferente dos três estados padrão de uma OS e está no nível da inspeção de um produto em particular
// Conseguiremos diferenciar algo aprovado condicionalmente porque terá passed como true E também terá itens que falharam
// Provavelmente é melhor se criar uma nova tela especificamente para mostrar todas as inspeções esperando aprovação condicional
// A tela para aprovação do PIN pode ser a visualização da QI, inclusive assim o aprovador pode ver os textos e as imagens
</script>