import { workspaceDb } from '../db/db';
import Dao from './dao';

export default class WorkspaceDao extends Dao {
  constructor() {
    super();
  }

  db() {
    return workspaceDb.db();
  }

  // This method should involve the same indexes listed at #createIndexes
  async deleteIndexes() {
    const getIndexesResult = await this.db().getIndexes();
    for (const idx of getIndexesResult.indexes) {
      if (idx.type != 'special') {
        await this.db().deleteIndex(idx);
      }
    }
  }

  // This method should involve the same indexes listed at #deleteIndexes
  async createIndexes() {
    // We first delete the indexes to make sure any changes in their definition
    // are applied when the user receives a new version and acts in a way
    // that triggers this method.
    await this.deleteIndexes();

    await this.db().createIndex({
      index: {
        fields: ['productionOrderId'],
        name: 'idx_production_order_id',
        ddoc: 'idx_production_order_id',
      },
    });
    await this.db().createIndex({
      index: {
        fields: ['productId'],
        name: 'idx_product_id',
        ddoc: 'idx_product_id',
      },
    });
    await this.db().createIndex({
      index: {
        fields: ['productId', 'passed'],
        name: 'idx_product_id_passed',
        ddoc: 'idx_product_id_passed',
      },
    });
    await this.db().createIndex({
      index: {
        fields: ['productionOrderId', 'nfcTagRecorded'],
        name: 'idx_production_order_id_nfc_tag_recorded',
        ddoc: 'idx_production_order_id_nfc_tag_recorded',
      },
    });
    await this.db().createIndex({
      index: {
        fields: ['allProductsPassed', 'allNfcTagsRecorded', 'prodUseNfcTag'],
        name: 'idx_production_order_filters',
        ddoc: 'idx_production_order_filters',
      },
    });
    await this.db().createIndex({
      index: {
        fields: ['conditionalApprovalStatus'],
        name: 'idx_qi_conditional_approval_status',
        ddoc: 'idx_qi_conditional_approval_status',
      },
    });
  }
}
